
      <div class="modal-content">
        <div class="modal-header">
          <button mat-icon-button class="close" (click)="closeModal()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="modal-body">
          <p>{{modalMessage}}</p>
          <mat-form-field class="input" *ngIf="hasInput">
            <input matInput [(ngModel)]="inputValue">
          </mat-form-field>
        </div>
        <div class="modal-footer">
          <button type="button" class="gold-stroked-button" (click)="closeModal()">Cancelar</button>
          <button type="button" class="gold-color-button" (click)="customAction()">{{modalOption}}</button>
        </div>
      </div>

  
 